/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Layout from "../../components/layout";
import Container from "../../components/container";
import {mainContent, introSection, description, smallMarginTopSubstitute} from "../subpage.module.scss";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(Layout, {
    pageTitle: "NewsUbersicht",
    neutralPage: true
  }, React.createElement("main", {
    className: mainContent
  }, React.createElement("section", {
    className: introSection
  }, React.createElement(Container, {
    mdxPageWidth: true
  }, React.createElement("div", {
    className: description
  }, "2022 – heute"), React.createElement(_components.h1, null, "News Übersicht"), React.createElement(_components.p, null, "News, neuste Produkte, wichtigste Fachmessen und unser Wissen stets im Dienst von Forschung und Entwicklung."), React.createElement(_components.h1, null, React.createElement("div", {
    style: {
      marginTop: 60
    },
    className: "text-green"
  }, "2024")), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news/connectathon-24",
    target: "_blank",
    rel: "noreferrer"
  }, "Teilnahme am Connectathon 2024: Erfolgreiche Tests von klassischen und FHIR-basierten Profilen >")), React.createElement(_components.p, null, "Event - August 2024"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news/tie-talk-ch24",
    target: "_blank",
    rel: "noreferrer"
  }, "TIE Talk Zürich, wir sagen Danke >")), React.createElement(_components.p, null, "Event - Juni 2024"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news/ef-summercamp-zurich",
    target: "_blank",
    rel: "noreferrer"
  }, "Das Sommercamp 2024 der Entscheiderfabirk war in Zürich >")), React.createElement(_components.p, null, "Event Artikel - Juni 2024"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news/netzwoche-artikel",
    target: "_blank",
    rel: "noreferrer"
  }, "Smart reicht nicht mehr, die Zukunft des digitalen Spitals ist «liquid» >")), React.createElement(_components.p, null, "Fachartikel - Mai 2024"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-presserunde-dmea",
    target: "_blank",
    rel: "noreferrer"
  }, "DMEA Presserunde Patientenportal >")), React.createElement(_components.p, null, "Presserunde - April 2024"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-p-p-artikel-kh-it-journal",
    target: "_blank",
    rel: "noreferrer"
  }, "Interoperable Portale gestalten medizinische Versorgung neu >")), React.createElement(_components.p, null, "Artikel & Interview - März 2024"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-rlp",
    target: "_blank",
    rel: "noreferrer"
  }, "Versorgung erfolgreich vernetzen: Digitale Gesundheitsplattform in Rheinland-Pfalz >")), React.createElement(_components.p, null, "Artikel - März 2024"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-EF-wettbewerb",
    target: "_blank",
    rel: "noreferrer"
  }, "Zusammen mit Universitätsmedizin Rostock sind wir ein Gewinner des Wettbewerbs «Digitalisierungsthema der Gesundheitswirtschaft 2024» >")), React.createElement(_components.p, null, "News - Februar 2024"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-dmea24",
    target: "_blank",
    rel: "noreferrer"
  }, "Besuchen Sie uns an der DMEA 2024 in Berlin >")), React.createElement(_components.p, null, "News - Februar 2024"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-pp-live-usb",
    target: "_blank",
    rel: "noreferrer"
  }, "Universitätsspital Basel nimmt Patientenportal von the i-engineers in Betrieb >")), React.createElement(_components.p, null, "Breaking News - Januar 2024"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-willkommen-andreas",
    target: "_blank",
    rel: "noreferrer"
  }, "Andreas G. Henkel übernimmt die Schlüsselposition des Executive Director of Program and Product Portfolio Management >")), React.createElement(_components.p, null, "Interview - Januar 2024"), React.createElement(_components.h1, null, React.createElement("div", {
    style: {
      marginTop: 60
    },
    className: "text-green"
  }, "2023")), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-rheinland-pfalz-artikel",
    target: "_blank",
    rel: "noreferrer"
  }, "Mehr Informationseffizienz für Leistungserbringer, ihre Partner und Patienten >")), React.createElement(_components.p, null, "Artikel - Dezember 2023"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-sprechstunde-plus-artikel",
    target: "_blank",
    rel: "noreferrer"
  }, "Klinik Hirslanden Zürich am Swiss eHealth Forum: Sprechstunden – digitalisiert, automatisiert, effizient >")), React.createElement(_components.p, null, "Artikel - August 2023"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-sprechstunde-plus",
    target: "_blank",
    rel: "noreferrer"
  }, "Modul integration Anästhesie-Cockpit bei der Privatklinik-Gruppe der Hirslanden AG >")), React.createElement(_components.p, null, "News - Juni 2023"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-feedbackgeber",
    target: "_blank",
    rel: "noreferrer"
  }, "Digitaler Fortschritt braucht die richtigen Partner >")), React.createElement(_components.p, null, "Artikel - Februar 2023"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-dmea-berlin-2023",
    target: "_blank",
    rel: "noreferrer"
  }, "Die DMEA - Fachmesse Connecting Digital Health steht vor der Tür >")), React.createElement(_components.p, null, "News - Februar 2023"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-neujahr-2023",
    target: "_blank",
    rel: "noreferrer"
  }, "Auf ein gutes Neues Jahr >")), React.createElement(_components.p, null, "News - Januar 2023"), React.createElement(_components.h1, null, React.createElement("div", {
    style: {
      marginTop: 60
    },
    className: "text-green"
  }, "2022")), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-pp-live-ksbl",
    target: "_blank",
    rel: "noreferrer"
  }, "Kantonsspital Baselland schaltet das Patientenportal von the i-engineers live >")), React.createElement(_components.p, null, "Breaking News - 01. Dezember 2022"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-leistungsanforderung",
    target: "_blank",
    rel: "noreferrer"
  }, "Digitale Prozesse ohne Medienbrüche – Digitale Leistungsanforderung >")), React.createElement(_components.p, null, "Artikel - 20. Oktober 2022"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://tie.ch/news-kundenevent-hamburg",
    target: "_blank",
    rel: "noreferrer"
  }, "TIE Talk Kundenevent/Anwendertagung, the i-engineers in Hamburg >")), React.createElement(_components.p, null, "Event Review - 28. Juni & 29. Juni 2022"), React.createElement(_components.h2, null, React.createElement("a", {
    href: "https://www.zhaw.ch/de/forschung/departementsuebergreifende-kooperationen/digital-health-lab/shift-smart-hospital-integrated-framework-tools-solutions/",
    target: "_blank",
    rel: "noreferrer"
  }, "Wir sind Forschungspartner der ZHAW >")), React.createElement(_components.p, null, "ab 2022")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
